const Terms = () => {

    const termsList = [
        {
            title: '1. Acceptance',
            decription: 'By using or visiting our website and Chrome extensions, you signify that you have read, understand, and agree to be bound by our "Terms of service or Agreement".'
        },
        {
            title: '2. Ownership',
            decription: 'You may not claim intellectual or exclusive ownership rights to any of our products. All products are property of z-booster.com. They are provided “as is” without warranty of any kind, expressed or implied. We shall not be liable for any damages, including but not limited to, direct, indirect, special, incidental, or consequential damages or losses that occur out of the use or inability to use our products.'
        },
        {
            title: '3. Support',
            decription: 'Support and automatic product updates are provided for the term defined by the account type and are based on the date of purchase. After the subscription has expired a new subscription will need to be purchased to continue receiving support and product updates.'
        },
        {
            title: '4. Payments & Refund Policy',
            decription: 'Our order process is conducted by our online reseller lemonsqueezy.com. lemonsqueezy.com is the Merchant of Record for all our orders. lemonsqueezy provides all customer service inquiries and handles returns. We provide a 7-day money-back guarantee as part of their refund policy from the moment of purchase. You can request money back if the service really doesn\'t satisfy you or you are faced with some service issues. Please, email support[at]z-booster.com and send your order details. We care about our services and customers. We want to ensure that you are happy with your purchase and that our products work correctly in your website environment. If you have any questions, problems, or concerns with any of our products then please let me know by using the email support[at]z-booster.com.'
        },
        {
            title: '5. Money Back Guarantee',
            decription: 'If you have any irresolvable issues getting one of our services to work or if you’re completely unhappy with it, we will be happy to provide a full refund within 7 days* of the original purchase.'
        },
        {
            title: '6. Transactional Emails',
            decription: 'Z-Booster may on occasion send you email notifications related to your service. These transactional emails may include notification of changes to our terms and conditions, license expiration notices, and other transactional emails related to your purchase.'
        },
        {
            title: '7. Price Changes',
            decription: 'Z-Booster reserves the right to change the price of any service at any time. Any price change will be reflected on z-booster.com. If any price is lowered, you will not be entitled to a refund for the difference in the price from the time you purchased the service.'
        },
        {
            title: '8. Sharing of Data',
            decription: 'We will not share your data with any third parties under any circumstances.'
        },
        {
            title: '9. Data Privacy',
            decription: 'Please refer to our Privacy Policy page.'
        }
    ]

    return (
        <>
            <div class="p-8 px-0">
                <div class="p-4 py-4 mt-12">
                    <h4 class="text-4xl font-bold text-gray-800 tracking-widest text-center">Terms Of Service</h4>
                    <div class="space-y-2 px-2 xl:px-16 mt-12">

                        {
                            termsList.map((item, index) => {
                                return <div><div class="flex items-center h-16">
                                    <span class="text-lg text-black font-bold">{item.title}</span>
                                </div>
                                    <div class="flex items-center">
                                        <span class="text-gray-900">{item.decription}</span>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default Terms;