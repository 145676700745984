import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "../layout";
import Home from "../home";
import Pricing from "../pricing";
import FAQ from "../faq";
import Terms from "../terms";
import Privacy from "../privacy";


const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="pricing" element={<Pricing />} />
                    <Route path="faq" element={<FAQ />} />
                    <Route path="terms" element={<Terms />} />
                    <Route path="privacy" element={<Privacy />} />
                </Route>
                <Route path="*" element={<div>404</div>} />
            </Routes>
        </BrowserRouter>
    )
}
export default Router;